@media only screen and (max-width: 600px) {
    .select {
        width: 100%;
    }
}

header {
    background-color: "#36363c",
}

.style {
    margin-top: 5px;
    margin-left: 8px;
    color: rgba(0, 0, 0, 0.54);
    padding: 0px;
    font-size: 1rem;
    letter-spacing: 0.00938em;
    line-height: 1;
    font-weight: 400;
    font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
}