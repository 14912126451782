@media (max-width: 769px) {
    .MuiPaper-root {
        width: 100%;
    }
    .tableWrapper {
        max-width: 440;
    }
}

@media (min-width: 600px) {
    .MuiTab-root {
        min-width: 100px;
    }
}